.faq-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 16px;
}

.faq-question {
  font-size: 18px;
  font-weight: 650;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  left: 0;
  align-items: center;
  text-align: left;
  height: 100%;
}
.ausklappen {
  transition: 150ms;
}

.ausklappen:hover {
  color: var(--markup-color);
  transform: scale(1.3);
}

.faq-answer {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
  cursor: pointer;
  max-height: 300px; /* adjust this value to fit your content */
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.faq-answer.open {
  max-height: auto;
  transition: max-height 150ms ease-in-out;
}
.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 150ms ease-in-out;
}

.faq-answer.open {
  max-height: 1000px; /* adjust this value to fit your content */
  transition: max-height 0.5s ease-in-out;
}


