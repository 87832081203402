.PhotoGallery {
  margin: 0;
  width: 100%;
}
/* Style for the preview container */
.PhotoGallery-preview {
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Style for the caption of the preview */
.PhotoGallery-preview figcaption {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 1.2em;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* Style for the preview image */
.PhotoGallery-preview img {
  user-select: none;
  max-width: calc(100% - 300px);
  max-height: calc(100% - 300px);
}

/* Style for the preview close button */
.PhotoGallery-preview svg {
  cursor: pointer;
  height: auto;
  width: 50px;
  padding: 50px;
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}

/* Hover style for the preview close button */
.PhotoGallery-preview svg:hover {
  opacity: 1;
}

/* Style for the preview close button icon */
.PhotoGallery-preview svg path {
  fill: #ffffff;
}

/* Style for the background of the preview */
.PhotoGallery-preview .background {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.8);
}

/* Media queries for smaller screens */
@media (max-width: 1200px) {
  .PhotoGallery-preview img {
    max-width: calc(100% - 210px);
    max-height: calc(100% - 210px);
  }

  .PhotoGallery-preview svg {
    width: 40px;
    padding: 32.5px;
  }
}

@media (max-width: 768px) {
  .PhotoGallery-preview img {
    max-width: calc(100% - 120px);
    max-height: calc(100% - 120px);
  }

  .PhotoGallery-preview svg {
    width: 30px;
    padding: 15px;
  }
}


/* Photo Gallery List ------------------------------------- */


/* Set display to flex with wrapping and a margin of 100px on all sides */
.PhotoGallery-list {
  display: flex;
  flex-wrap: wrap;
  margin: 100px;
}

/* Set cursor to pointer, calculate width to be 25% minus 100px, and set margin of 50px on all sides */
.PhotoGallery-list > div {
  cursor: pointer;
  width: calc(25% - 100px);
  margin: 50px;
}

/* Set position to relative and overflow to hidden, and calculate padding-bottom to be 100% */
.PhotoGallery-list > div div {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}

/* Set position to absolute, left and top to 0, width and height to 100%, and object-fit to cover */
/* Also set a transition effect of 0.2s on transform */
.PhotoGallery-list img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-out;
}

/* Add a transform scale effect of 1.1 when hovering over the image */
.PhotoGallery-list > div:hover img {
  transform: scale(1.1);
}

/* Adjust margin and width on smaller screens */
@media (max-width: 1200px) {
  .PhotoGallery-list {
    margin: 70px;
  }

  .PhotoGallery-list > div {
    width: calc(33.33% - 70px);
    margin: 35px;
  }
}

/* Adjust margin and width on even smaller screens */
@media (max-width: 768px) {
  .PhotoGallery-list {
    margin: 40px;
  }

  .PhotoGallery-list > div {
    width: calc(50% - 40px);
    margin: 20px;
  }
}
