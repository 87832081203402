footer {
    height: 40px;
    background-color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    margin: 0px;
    width: 100%;
}
footer a {
    margin: 10px;
    transition: 0.5s;
}
footer a:hover {
    transform: scale(1.05);
    text-decoration: nonex;
}