/* ========== POPUP CONTAINER ========== */

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1010;
  
}

/* ========== POPUP ========== */

.popup {
  position: relative;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  max-height: 80%;
  overflow: auto;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  z-index: 1010;
}
.popup p img {
  width: 70%;
}

/* ========== BUTTONS ========== */

.buttons {
  margin: 15px;
  display: flex;
  justify-content: space-between;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}
.close-icon {
  display: block;
  font-size: 24px;
  color: #555;
  transition: 0.3s;
}

.close-icon:hover {
  color: var(--markup-color);
  transform: scale(1.2);
}

/* ========== HEADER ========== */

.header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

h2 {
  margin: 0;
  font-size: 28px;
  font-weight: normal;
  color: var(--text-color);
}

.popup .image {
  margin-right: 15px;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  object-fit: cover;
}

/* ========== DETAILS ========== */

.details {
  margin-top: 20px;
}

.label {
  margin-right: 10px;
  width: 120px;
  font-weight: bold;
}

.biography,
.schicksal {
  text-align: justify;
  margin: 20px;
}

.sources {
  margin: 0 20px;
  text-align: left;
}
