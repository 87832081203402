/* sizing of map container */
.leaflet-container {
  height: 50vh;
  width: 100%;
}
.leaflet-popup {
  text-align: center;
}
.click {
  cursor: pointer;
}
