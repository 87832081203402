@font-face {
  font-family: "Open Sans";
  src: url("./static/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Merriweather";
  src: url("./static/fonts/Merriweather-Regular.ttf");
}

#root {
  width: 100%;
  min-height: 100vh;

  --background-color: #fff;
  --secondary-color: #ddd;
  --markup-color: rgb(172, 150, 30);
  --text-color: #333;
  --secondary-text-color: #444;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: "Merriweather", serif;
  font-size: 36px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  font-weight: 300;
  color: var(--secondary-text-color);
}

body {
  background-color: #f6f6f6;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
  color: var(--text-color);

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
/* LIST STYLE */
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.loaded {
  animation: fade-in 1s ease-in-out forwards;
}

li {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

/*Loading*/
.loader {
  border: 6px solid var(--markup-color); /* Loader color */
  border-top: 6px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animation duration and timing function */
  margin: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Error*/
.error {
  font-size: 24px;
  text-align: center;
  color: #ff4d4d; /* Error text color */
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}


p {
  margin-left: 5%;
  margin-right: 5%;
}
@media (max-width: 500px) {
  p {
    margin-left: 0;
    margin-right: 0;
    
  }
}
