.slideshow {
    position: relative;
    overflow: hidden;
    height: 75vh;
    background-color: #eee;
  }
  
  .slideshow img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 500xms ease-in-out;
  }
  .slideshow img.active {
    opacity: 1;
    /*animation: fade 10s ease-in-out forwards;*/
    position: relative;
  }

  @keyframes fade {
    0% {
      opacity: 0.1;
    }
    5% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }

