/* Style the search result table */
table {
  border-collapse: collapse;
  margin: 0;
  cursor: pointer;
  width: 100%;
  margin-bottom: 100px;
}

/* Style the table header */
th {
  background-color: var(--secondary-color);
  color: #444;
  font-weight: bold;
  padding: 12px;
  text-align: center;
  text-transform: uppercase;
}

/* Style the table cells */
td {
  border: 1px solid #ddd;
  color: #666;
  padding: 12px;
  min-width: 15vw;
}
.search-result-item:hover {
  background-color: #eee;
}

/* Style the search result item labels */
.search-result-label {
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;

}
.search-result-label:hover {
  color: var(--markup-color);
  transition: 0.3s;
}
@media (max-width: 900px) {
  /* Hide all table columns except the first and second ones */
  td:nth-of-type(4),
  th:nth-of-type(4) {
    display: none;
  }

  td:not(:nth-of-type(4)),
  th:not(:nth-of-type(4)) {
    width: 100%;
  }

  td:not(:nth-of-type(4)),
  th:not(:nth-of-type(4)) {
    padding: 10px 20px;
  }

  /* Hide the arrow indicators */
  .arrow-up,
  .arrow-down {
    display: none;
  }
}

@media (max-width:700px) {
  /* Hide all table columns except the first and second ones */
  td:not(:first-of-type):not(:nth-of-type(2)),
  th:not(:first-of-type):not(:nth-of-type(2)) {
    display: none;
  }

  /* Make the first and second columns take up the entire width of the screen */
  td:first-of-type,
  th:first-of-type,
  td:nth-of-type(2),
  th:nth-of-type(2) {
    width: 100%;
  }

  /* Add padding to the table cells containing the first and second columns */
  td:first-of-type,
  th:first-of-type,
  td:nth-of-type(2),
  th:nth-of-type(2) {
    padding: 10px 20px;
  }

  /* Hide the arrow indicators */
  .arrow-up,
  .arrow-down {
    display: none;
  }
}

@media (max-width: 450px) {
  /* Hide all table columns except the first one */
  td:not(:first-of-type),
  th:not(:first-of-type) {
    display: none;
  }

  /* Make the first column take up the entire width of the screen */
  td:first-of-type,
  th:first-of-type {
    width: 100%;
  }

  /* Add padding to the table cell containing the name */
  td:first-of-type,
  th:first-of-type {
    padding: 10px 20px;
  }

  /* Hide the arrow indicators */
  .arrow-up,
  .arrow-down {
    display: none;
  }
}
