
.contact-text{
  text-align: center;
}
p {
  margin-right: 8%;
  margin-left: 8%;
  
}
h3 {
  font-weight: 400;
  margin-right: 8%;
  margin-left: 8%;
  margin-bottom: 5vh;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: underline;
  color: rgb(172, 150, 30);
}
.faq-contact {
  margin-top: 0;
}