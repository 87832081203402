/* search */
.search {
  flex-direction: column;
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
  background-color: #fff;
}

/* form */
form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

/* advanced search */
.advancedSearch {
  border-top: 1px solid black;
}

.advancedSearch:hover {
  color: var(--markup-color);
  transition: 0.3s;
  font-size: 105%;
  border-color: var(--markup-color);
}

/* input and select */
input,
select {
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}

select {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2 7l8 8 8-8"/></svg>');
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 3%;
  text-decoration: none;
  -webkit-appearance: none;
}


/* gefunden */
.gefunden {
  margin-bottom: 10px;
}