.navbar {
  z-index: 1000;
  position: flex;
}
.topnav {
  overflow: hidden;
  background-color: var(--secondary-color);
  z-index: 5000;
}
.bild {
  width: 50px;
  transition: 0.3s;
}
.bild:hover {
  transform: scale(1.1);
}
.topnav a {
  float: left;
  display: flex;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  height: 50px;
  text-align: center;
  align-items: center;
}

.topnav a:hover {
  background-color: #ccc;
  text-decoration: none;
  color: var(--markup-color);
}
.topnav a.active {
  background-color: rgba(172, 150, 30, 0.7);
  color: white;
}

.topnav .icon {
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
}
.topnav .icon i {
  transform: scale(1.4);
  color: var(--text-color)
}

.nav-link select {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  font-size: 30px;
  width: 50px;
  height: 50px;
  color: var(--text-color);
}


@media screen and (max-width: 850px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: flex;
  }
}

@media screen and (max-width: 850px) {
  .topnav.responsive {
    position: fixed;
    width: 100%;
  }


  .topnav.responsive .icon {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: flex;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  .nav-link select {
    width: 90vw;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    padding: 0px;
    font-size: 18px;
  }
  
}
