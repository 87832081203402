.datenschutz-div {
    text-align: left;
    margin: 5vw;
}
.datenschutz-div h2 {
    font-size: 18pt;
    margin-bottom: 2vh;
}
.datenschutz-div p {
    margin: 0;
    margin-bottom: 5vh;
}