/* Slideshow */
#popup-what button{
  float: right;
}
#popup-what p {
  text-align: justify;
}


.line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  z-index: 10;
}
.headline {
  color: white;
  text-align: center;
  font-size: 3rem;
  z-index: 10;
}
.subline {
  color: white;
  text-align: center;
  font-size: 2rem;
}
.dunkel {
  
  filter: blur(0.5px);
  filter:brightness(0.85);
}
.scroll-unten {
  margin-bottom: 15vh;
  transition: 0.3s;
  scroll-behavior: smooth;
  cursor: pointer;
}

.scroll-unten:hover{
  transform: scale(1.05);

} 
.scroll-link:hover {
  color: rgb(172, 150, 30);
 
  text-decoration: none;
}

/* Main */

.main {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 5%;
  margin-bottom: 5%;
}
.main div:hover {
  transform: translate(0,-3px)
}
.main div {
  width: 310px;
  height: 310px;
  margin: 15px;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 10px 10px 10px lightgray;
  background-color: #dddddd50;
  transition: ease-in-out 500ms;
}
.main div h3 {
  font-family: "Merriweather", serif;
  margin-bottom: 0px;
}
.main div p {
  text-align: left;
  margin: 0;
}

.link {
  background-color: #ddd;
  border-radius: 10px;
  padding: 10px;
  transition: 0.5s;
  justify-self: end;
  box-shadow: 2px 2px 2px gray;
}
.link:hover {
  text-decoration: none;
  transform: scale(1.05);
  cursor: pointer;
}
.videos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  flex-wrap: wrap;
  margin-bottom: 5vh;
}
.yt {
  width: 45vw;
  height: calc(45vw*(9/16));
}
@media(max-width: 900px) {
  .yt {
    width: 90vw;
    height: calc(90vw *(9/16));
  }
}
@media(max-width: 600px) {
  .subline {
    display: none;
  }
}