.event {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.events {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.event .top {
    display: flex;
    gap: 100px;
}


.event button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.event button:hover {
  background-color: #0056b3;
}

.event .hide {
  display: none;
}
